import { boolean } from "boolean";
import { hasExpressFlowConfig } from "./components_expressflow/config/express_flow_config";
import { Country } from "./models/Country";

export enum AppType {
  Main = 'main',
  Offer = 'offer',
  Admin = 'admin',
  Test = 'test',
  ExpressFlow = 'expressflow',
  OfferDocument = 'offerdocument',
  OfferWebForm = 'offerwebform',
  RegistrationLink = 'registrationLink',
  DashboardLink = 'dashboardLink'
}

let _hostname = document.location.hostname;
const alternativeCustomHostname = `salesys.${_hostname.replace("salesys.", "")}`;

if (hasExpressFlowConfig(alternativeCustomHostname)) {
  //  Rewrite in case of issues with external domain.
  //  tryggsurf.salesys.se => salesys.tryggsurf.se
  _hostname = alternativeCustomHostname;
}

/**
 * Use this to fake the hostname, for example, for express flow testing.
 * @example export const hostname = "xn--slj-qla.salesys-next.elipt.is";
 */
export const hostname = _hostname;

let _appType: AppType = AppType.Main;

export const offerSubdomains = [
  'offert',
  'avtal',
  'avtalnya',
  'avtale'
];

if (offerSubdomains.find(subdomain =>
  hostname.startsWith(subdomain + '.'))) {
  _appType = AppType.Offer;

} else if (hostname.startsWith('admin.')) {
  _appType = AppType.Admin;

} else if (hostname.startsWith('test.') || window.sessionStorage?.getItem('isTest') === 'yes') {
  _appType = AppType.Test;

} else if (hasExpressFlowConfig()) {
  _appType = AppType.ExpressFlow;

} else if (
  hostname.startsWith('kopia.') ||
  hostname.startsWith('kopi.') // Norwegian.
) {
  //  Link is sent via SMS, hence why it is short.
  _appType = AppType.OfferDocument;

} else if (hostname.startsWith('hej.')) {
  _appType = AppType.RegistrationLink;

} else if (hostname.startsWith('skaffa.')) {
  _appType = AppType.OfferWebForm;

} else if (hostname.startsWith('statistik.')) {
  _appType = AppType.DashboardLink;
}

/**
 * The type of app, which is usually dependent on the current hostname.
 */
export const appType: AppType = _appType;
document.head.parentElement.setAttribute('data-apptype', appType);

export const isDeveloper = boolean(localStorage.getItem('isDeveloper') || localStorage.getItem('isTest'));

/**
 * Gets a country override for testing, if exists.
 */
export function getTestCountry(): Country | null {
  return window.sessionStorage.getItem('testCountry') as Country || null;
}